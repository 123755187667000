<template>
  <v-row>
    <v-col md="3" cols="12">
      <div class="statusTitle">In Progress</div>
      <div
        class="drop-zone"
        @drop="onDrop($event, 1)"
        @dragover.prevent
        @dragenter.prevent
      >
        <v-row>
          <v-col
            md="12"
            cols="12"
            v-for="item of listData.inprogress"
            :key="item"
          >
            <v-card
              class="drag-el"
              color="#ff000f59"
              dark
              max-width="200"
              draggable
              @dragstart="startDrag($event, item)"
            >
              <v-row>
                <v-col cols="10">
                  <v-card-title>
                    <div class="conOfCardTitle">
                      <div class="Name">{{ item.project_name }}</div>
                      <div class="Name">{{ item.name }}</div>
                    </div>
                  </v-card-title>
                </v-col>
                <v-col cols="2">
                  <div class="d-flex justify-end">
                    <v-menu bottom :left="true" :offset-y="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <template v-for="status in statusList">
                          <v-list-item :key="status">
                            <v-list-item-title
                              class="statusTxt"
                              @click="updateTaskStatus(status.status, item.id)"
                              >{{ status.title }}</v-list-item-title
                            >
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <a @click="openAssignDialog(item.id)" class="d-flex assignTo"
                >Assign To</a
              >
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col md="3" cols="12">
      <div class="statusTitle">Test 2</div>
      <div
        class="drop-zone"
        @drop="onDrop($event, 2)"
        @dragover.prevent
        @dragenter.prevent
      >
        <div
          v-for="item in listOne"
          :key="item.title"
          class="drag-el"
          draggable
          @dragstart="startDrag($event, item)"
        >
          {{ item.title }}
        </div>
      </div>
    </v-col>
  </v-row>
  <!-- 
  <div>
    <div
      class="drop-zone"
      @drop="onDrop($event, 1)"
      @dragover.prevent
      @dragenter.prevent
    >
      <div
        v-for="item in listOne"
        :key="item.title"
        class="drag-el"
        draggable
        @dragstart="startDrag($event, item)"
      >
        {{ item.title }}
      </div>
    </div>
    <div
      class="drop-zone"
      @drop="onDrop($event, 2)"
      @dragover.prevent
      @dragenter.prevent
    >
      <div
        v-for="item in listTwo"
        :key="item.title"
        class="drag-el"
        draggable
        @dragstart="startDrag($event, item)"
      >
        {{ item.title }}
      </div>
    </div>
  </div> -->
</template>
<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data() {
    return {
      users: {},
      assignData: [],
      user_id: "",
      selectedTaskId: "",
      taskId: "",
      assignDialog: false,
      listData: [],
      task_id: "",
      id: "",
      status: "",
      statusList: [
        {
          status: "0",
          title: "todo",
        },
        {
          status: "1",
          title: "PENDING",
        },
        {
          status: "2",
          title: "review",
        },
        {
          status: "3",
          title: "inprogress",
        },
        {
          status: "4",
          title: "done",
        },
        {
          status: "5",
          title: "cancel",
        },
      ],
      items: [
        {
          id: 0,
          title: "Item A",
          list: 1,
        },
        {
          id: 1,
          title: "Item B",
          list: 1,
        },
        {
          id: 2,
          title: "Item C",
          list: 2,
        },
      ],
    };
  },
  methods: {
    getTaskData() {
      this.listData = [];
      this.updateData = [];
      apiServices.get(`my-tasks`).then((res) => {
        if (res) {
          this.listData = res.data;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },

    updateTaskStatus(status, id) {
      this.updateData = [];
      const payload = {
        status: status,
        task_id: id,
      };
      apiServices.put(`task/status`, payload).then((res) => {
        if (res) {
          this.updateData = res.data;
          console.log("updateData", this.updateData);
          this.getTaskData();
          this.status = "";
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },

    getUserListData() {
      apiServices.get(`user/users`).then((res) => {
        if (res) {
          this.users = res.data;
          console.log("users", this.users);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    openAssignDialog(task_id) {
      this.selectedTaskId = task_id;
      this.assignDialog = true;
    },

    closeAssignDialog() {
      this.assignDialog = false;
    },
    assignTask() {
      this.assignData = [];

      apiServices
        .post(
          `assign-user-task?&task_id=${this.selectedTaskId}&user_id=${this.user_id}`
        )
        .then((res) => {
          if (res) {
            this.isLoadingSearch = false;
            this.assignData = res.data;
            this.successMessage = res.message;
            this.successSnackbar = true;
            this.closeAssignDialog();
            this.getTaskData();
            this.isLoading = false;
          } else {
            this.errorMessage = res.data.error || "Somthing Error";
            this.errorSnackbar = true;
            this.isLoading = false;
          }
        });
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.items.find((item) => item.id == itemID);
      item.list = list;
    },
  },
  created() {
    this.getTaskData();
    this.getUserListData();
  },
  computed: {
    listOne() {
      return this.items.filter((item) => item.list === 1);
    },
    listTwo() {
      return this.items.filter((item) => item.list === 2);
    },
  },
};
</script>
<style scoped>
.drop-zone {
  background-color: #f4f5f7;
  min-height: 300px;
  padding: 20px;
  border-radius: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>
